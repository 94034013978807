<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">

    <h2 class="text-white mb-10">{{ app.name }}</h2>

    <div class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Nowa aplikacja</h3>
        </div>
      </div>
      <!--end::Card header-->
      <!--begin::Content-->

      <div class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-row-fluid">

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Nazwa aplikacji</label>
                <div class="col-lg-8 fv-row">
                  <input type="text" :class="{ 'is-invalid': errors.name }" @change="errors.name = false" class="form-control form-control-lg form-control-solid" v-model="app.name" />
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.name }}</div>
                </div>
              </div>

              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Opis przeznaczenia aplikacji</label>
                <div class="col-lg-8 fv-row">
                  <textarea :class="{ 'is-invalid': errors.description }" @change="errors.description = false" class="form-control" rows="10" v-model="app.description"></textarea>
                  <div class="fv-plugins-message-container invalid-feedback">{{ errors.description }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button @click="$router.back()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
          <button @click="save" class="btn btn-primary" :disabled="requestSend">Dodaj aplikację</button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AppsAdd',
  inject: ['$profile', '$organisation'],
  props: ['id'],
  data() {
    return {
      requestSend: false,
      app: {
        'name': '',
        'description': ''
      },
      errors: {
        'name': '',
        'description': ''
      }
    }
  },
  mounted () {
  },
  methods: {
    save: function() {
      var self = this;
      self.requestSend = true;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      var params = {
        'name': this.app.name,
        'description': this.app.description
      };
      this.$root.$refs.api.post('organisations/' + id + '/apps', params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.app = response.data;
          self.errors = {};
          self.$router.push(self.app.id);
        }
      })
      .catch(function () {
          self.requestSend = false;
      });
    }
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
